<template>
  <component :is="contentComponent" />
</template>

<script>

const _m = () => import('@/pages/home/m.vue') //代码切割
const _pc = () => import('@/pages/home/pc.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'thebookofanswer.info - Your Guide to Reliable Answers',
      meta: [
        {
          name: 'description',
          content: 'Discover accurate and insightful answers to your questions with thebookofanswer.info. Powered by Ghat GPT, our platform provides reliable guidance on a wide range of topics. Enhance your knowledge and make informed decisions with our expertly generated content.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
      "adsensConfig"
    ])
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = _pc
      return
    }
    this.contentComponent = _m
  },
  async mounted () {
    // await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  methods: {
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        // this.$eventrack('adscript_loaded', 'expose');
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        // this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        // this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      // this.$eventrack('adscript_add_success', 'expose');
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');
    },
  },
};
</script>
